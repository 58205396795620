import React from 'react';
import {Stack, TextField} from "@mui/material";
import useStyles from "./formStyles";

const TextFieldRenderer = ({label, name, onChange, disabled, value, style = {}, error = '', placeholder, type = 'text', min = undefined, max, maxLength = undefined, startAdornment = undefined, endAdornment = undefined, styles = useStyles(), isFromGeolocation, ...rest}) => {

    return (<Stack direction={'column'} spacing={0.5} sx={{width: '100%'}}>
            <span className={styles.label}>{label}</span>
            <TextField {...rest}
                       name={name}
                       InputProps={{ref: rest?.InputProps?.ref, style: {...style, height: '40px'}, startAdornment, endAdornment, classes:{ notchedOutline: styles.input}, autoComplete: rest?.autoComplete || 'on'}}
                       inputProps={{ ref: rest?.inputProps?.ref, onChange: rest?.inputProps?.onChange, style: {height: '40px', paddingBottom: '18px'}, min, max, maxLength}}
                       style={{...style, height: '40px'}}
                       onChange={onChange}
                       type={type}
                       min={min}
                       max={max}
                       disabled={disabled}
                       error={!!error}

                       placeholder={placeholder}
                       FormHelperTextProps={{
                           classes:{
                               root: isFromGeolocation ? styles.helperLocationText : styles.helperText
                           }
                       }}
                       helperText={error}
                       value={value}
                       fullWidth/>
    </Stack>

    );
};


TextFieldRenderer.propTypes = {

};

export default TextFieldRenderer;
