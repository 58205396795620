import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@mui/material/SvgIcon";

const DeletePlayerConnectionIcon = props => {
    return (
        <SvgIcon {...props} width="18" height="20" viewBox="0 0 18 20" >
            <path d="M12.3333 4.99996V4.33329C12.3333 3.39987 12.3333 2.93316 12.1517 2.57664C11.9919 2.26304 11.7369 2.00807 11.4233 1.84828C11.0668 1.66663 10.6001 1.66663 9.66667 1.66663H8.33333C7.39991 1.66663 6.9332 1.66663 6.57668 1.84828C6.26308 2.00807 6.00811 2.26304 5.84832 2.57664C5.66667 2.93316 5.66667 3.39987 5.66667 4.33329V4.99996M7.33333 9.58329V13.75M10.6667 9.58329V13.75M1.5 4.99996H16.5M14.8333 4.99996V14.3333C14.8333 15.7334 14.8333 16.4335 14.5608 16.9683C14.3212 17.4387 13.9387 17.8211 13.4683 18.0608C12.9335 18.3333 12.2335 18.3333 10.8333 18.3333H7.16667C5.76654 18.3333 5.06647 18.3333 4.53169 18.0608C4.06129 17.8211 3.67883 17.4387 3.43915 16.9683C3.16667 16.4335 3.16667 15.7334 3.16667 14.3333V4.99996" stroke="#7E8CA7" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </SvgIcon>
    );
};

DeletePlayerConnectionIcon.propTypes = {
};

export default DeletePlayerConnectionIcon;
