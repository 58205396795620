import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {TableCell, TableRow} from "@mui/material";


const UserClubTableRow = ({ data = {}}) => {

  return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell component="th" scope="row">
                {data?.name}
            </TableCell>
            <TableCell>
                {data?.isPremium? 'Yes' : 'No'}
            </TableCell>
            <TableCell>
                {data?.isFC? 'Yes' : 'No'}
            </TableCell>
        </TableRow>
  );
};

UserClubTableRow.propTypes = {};

export default UserClubTableRow;
