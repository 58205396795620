export const GET_CLUBS_SA = `
query($term: String, $skip: Int, $limit: Int) {
  getClubsSA(term: $term, skip: $skip, limit: $limit) {
    _id
    name
    abbreviation
    logo
    clubAdminUsers
    location {
      address
      coordinates
    }
    founded
    country
    sport
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isFC
    isPremium
    timezone
  }
}`;

export const IS_PREMIUM = `
mutation ToggleIsPremiumSA($clubId: String!, $isPremium: String!) {
  toggleIsPremiumSA(clubId: $clubId, isPremium: $isPremium) {
    _id
    name
    abbreviation
    logo
    location {
      address
      coordinates
      type
    }
    founded
    clubAdminUsers
    country
    sport
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isFC
    isPremium
    timezone
  }
}
`;

export const EDIT_CLUB = `
mutation editClub(
  $clubId: String!,
  $name: String,
  $abbreviation: String,
  $founded: Int,
  $country: String,
  $sport: String,
  $isPremium: String,
  $isFC: Boolean,
  $timezone: String
  ) {
  editClub(
    clubId: $clubId,
    name: $name,
    abbreviation: $abbreviation,
    founded: $founded,
    country: $country,
    sport: $sport,
    isPremium: $isPremium,
    isFC: $isFC,
    timezone: $timezone
  ) {
    _id
    name
    abbreviation
    logo
    location {
      address
      coordinates
      type
    }
    founded
    clubAdminUsers
    country
    sport
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isFC
    isPremium
    timezone
  }
}
`;

export const CREATE_CLUB_SA = `
  mutation createClubSA($name: String!, $abbreviation: String, $founded: Int, $country: String!, $sport: String!, $contactInfo: ContactInfoInput, $location: LocationInput, $leagues: [String], $timezone: String, $isFC: Boolean) {
    createClubSA(name: $name, abbreviation: $abbreviation, founded: $founded, country: $country, sport: $sport, contactInfo: $contactInfo, location: $location, leagues: $leagues, timezone: $timezone, isFC: $isFC) {
      _id
    }
  }
`;

export const REGISTER_USER_SA = `
 mutation RegisterUserSa($firstName: String!, $lastName: String, $mobile: String!, $email: String, $role: String!, $clubId: String, $teamId: String) {
    RegisterUserSa(firstName: $firstName, lastName: $lastName, mobile: $mobile, email: $email, role: $role, clubId: $clubId, teamId: $teamId) {
      _id
    }
  }
`;

export const REMOVE_CLUB_ADMIN_SA = `
 mutation RemoveClubAdmin($clubId: String, $userId: String, $deleteUser: Boolean) {
    removeClubAdmin(clubId: $clubId, userId: $userId, deleteUser: $deleteUser)
  }
`;

export const OFFICIAL_LEAGUE = `
  fragment officialLeague on OfficialLeagueQlType {
	_id
	logo
	name
	description
	website
	email
	country
	isOfficial
	sport
	connected_clubs
	connected_teams
	createdAt
	updatedAt
  }
`;

export const OFFICIAL_LEAGUE_COMPETITION = `
  fragment officialLeagueCompetition on OfficialCompetitionModelInterfaceQlType {
  _id
	type
	ageGroup
	name
	format
	startYear
	location {
		type
		address
		coordinates
	}
	clubsInCompetition
	teamsInCompetition
}`;

export const OFFICIAL_LEAGUE_FIXTURE = `
  fragment officialLeagueFixture on OfficialFixtureQlType {
	_id
	fixtureName
	leagueId
	competitionId
	createdAt
	updatedAt
  }
`;

export const OFFICIAL_LEAGUE_FIXTURE_MATCH = `
  fragment officialLeagueFixtureMatch on OfficialFixtureMatchQlType {
    _id
	homeTeam
	awayTeam
	date
	time
	venue
	location {
		type
		address
		coordinates
	}
	status
	score
	competitionId
	fixtureId
	leagueId
	createdAt
	updatedAt
  }
`;

export const CREATE_OFFICIAL_LEAGUE_SA = `
mutation CreateOfficialLeague(
  $name: String
  $description: String
  $logo: String
  $website: String
  $email: String
  $country: String
  $sport: String
  $connected_clubs: [String]
  $connected_teams: [String]
  $competitions: [OfficialCompetitionWithFixturesAndMatchesInputQlType]
) {
  createOfficialLeague(
    name: $name
    description: $description
    logo: $logo
    website: $website
    email: $email
    country: $country
    sport: $sport
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
    competitions: $competitions
  ) {
    league {
      _id
      logo
      name
      description
      website
      email
      country
      sport
      connected_clubs
      connected_teams
      createdAt
      updatedAt
    }
    competitions {
      _id
      leagueId
      type
      ageGroup
      name
      format
      startYear
      location {
        type
        address
        coordinates
      }
      clubsInCompetition
      teamsInCompetition
    }
    fixtures {
      _id
      fixtureName
      leagueId
      competitionId
      createdAt
      updatedAt
    }
    matches {
      _id
      leagueId
      competitionId
      fixtureId
      homeTeam
      awayTeam
      date
      time
      venue
      location {
        type
        address
        coordinates
      }
      status
      score
    }
  }
}
`;

export const GET_OFFICIAL_LEAGUES_SA = `
query($clubId: String) {
  getOfficialLeagues(clubId: $clubId) {
    league {
      _id
      logo
      name
      description
      website
      email
      country
      sport
      isOfficial
      connected_clubs
      connected_teams
      createdAt
      updatedAt
    }
    competitions {
      _id
      leagueId
      type
      ageGroup
      name
      format
      startYear
      location {
        type
        address
        coordinates
      }
      clubsInCompetition
      teamsInCompetition
      createdAt
      updatedAt
      fixtures {
        _id
        fixtureName
        leagueId
        competitionId
        createdAt
        updatedAt
        matches {
          _id
          leagueId
          competitionId
          fixtureId
          homeTeam
          awayTeam
          date
          time
          venue
          location {
            type
            address
            coordinates
          }
          status
          score
          createdAt
          updatedAt
        }
      }
    }
  }
}`;

export const GET_OFFICIAL_LEAGUE_COMPETITIONS_SA = `
 ${OFFICIAL_LEAGUE_COMPETITION}
  query ($leagueId: String){
   GetOfficialLeagueCompetitions(leagueId: $leagueId) {
      ...officialLeagueCompetition
    }
}`;

export const GET_OFFICIAL_LEAGUE_FIXTURES_SA = `
 ${OFFICIAL_LEAGUE_FIXTURE}
  query ($leagueId: String, $competitionId: String){
   getOfficialLeagueFixtures(leagueId: $leagueId, competitionId: $competitionId) {
      ...officialLeagueFixture
    }
}`;

export const GET_OFFICIAL_LEAGUE_MATCHES_SA = `
 ${OFFICIAL_LEAGUE_FIXTURE_MATCH}
 query($leagueId: String, $competitionId: String, $fixtureId: String) {
  getOfficialLeagueMatches(
    leagueId: $leagueId
    competitionId: $competitionId
    fixtureId: $fixtureId
  ) {
    ...officialLeagueFixtureMatch
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_SA = `
mutation EditOfficialLeague(
  $leagueId: String
  $logo: String
  $name: String
  $description: String
  $website: String
  $email: String
  $country: String
  $sport: String
  $connected_clubs: [String]
  $connected_teams: [String]
) {
  editOfficialLeague(
    leagueId: $leagueId
    logo: $logo
    name: $name
    description: $description
    website: $website
    email: $email
    country: $country
    sport: $sport
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
  ) {
    _id
    logo
    name
    description
    website
    email
    country
    sport
    isOfficial
    connected_clubs
    connected_teams
    createdAt
    updatedAt
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_COMPETITION_SA = `
mutation EditOfficialLeagueCompetition(
  $_id: String
  $type: String
  $ageGroup: String
  $name: String
  $startYear: String
  $format: String
  $location: LocationInput
  $connected_clubs: [String]
  $connected_teams: [String]
) {
  EditOfficialCompetitionSA(
    _id: $_id
    type: $type
    ageGroup: $ageGroup
    name: $name
    startYear: $startYear
    format: $format
    location: $location
    connected_clubs: $connected_clubs
    connected_teams: $connected_teams
  ) {
    _id
    leagueId
    type
    ageGroup
    name
    format
    startYear
    location {
      type
      address
      coordinates
    }
    clubsInCompetition
    teamsInCompetition
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_FIXTURE_SA = `
mutation EditOfficialLeagueFixture($fixtureId: String, $fixtureName: String) {
  editOfficialFixture(fixtureId: $fixtureId, fixtureName: $fixtureName) {
    _id
    fixtureName
    leagueId
    competitionId
    createdAt
    updatedAt
  }
}`;

export const EDIT_OFFICIAL_LEAGUE_FIXTURE_MATCH_SA = `
mutation EditOfficialLeagueFixtureMatch(
  $matchId: String
  $homeTeam: String
  $awayTeam: String
  $date: String
  $time: String
  $venue: String
  $status: String
  $score: String
  $location: LocationInput
) {
  editOfficialFixtureMatch(
    matchId: $matchId
    homeTeam: $homeTeam
    awayTeam: $awayTeam
    date: $date
    time: $time
    venue: $venue
    status: $status
    score: $score
    location: $location
  ) {
    _id
    leagueId
    competitionId
    fixtureId
    homeTeam
    awayTeam
    date
    time
    venue
    location {
      type
      address
      coordinates
    }
    status
    score
    createdAt
    updatedAt
  }
}`;

const PROFILE_WITH_INVITATIONS = `
    fragment profileWithInvitation on ProfileWithInvitations {
     _id
    firstName
    lastName
    mobile
    club 
    teams
    yearOfBirth
    userId
    parents
    invitations {
         _id
        fromId
        invitationType
        invitationToType
        toId
        status
        context { 
                message
                itemId
                itemType
        }
     }
    }
`;
export const GET_USER_PROFILES_SA = `
 ${PROFILE_WITH_INVITATIONS}
  query GetClubProfilesSA($userId: String){
   getClubProfilesSA(userId:  $userId) {
      ...profileWithInvitation
    }
}`;

export const RESOLVE_CLUBS = `
  query ($ids: [String]){
   ResolveClubs(ids:  $ids) {
      _id
      name
      abbreviation
      logo
      clubAdminUsers
      location {
        address
        coordinates
      }
      founded
      country
      sport
      contactInfo {
        phones
        emails
        websiteUrl
      }
      leagues
      isFC
      isPremium
      timezone
      theme
      totalMembers
    }
}`;

export const USER_WITH_INVITATIONS_FIELDS = `
  fragment userFields on UserWithInvitations {
    _id
    mobile
    email
    firstName
    lastName
    roles
    invitations {
         _id
        fromId
        invitationType
        invitationToType
        status
        toId
        context { 
                message
                itemId
                itemType
        }
    }
    clubs {
       _id
       name
       abbreviation
       isFC
       isPremium
     }
    teams {
     _id
     name
     club {
       _id
       abbreviation
       name
       isFC
       isPremium
     }
     teamAdminUsers
     coaches
     teamCode
    }
  }
`;

export const SEARCH_USER_WITH_MOBILE_SA = `
 ${USER_WITH_INVITATIONS_FIELDS}
  query SearchUserWithMobileSA($mobile: String!) {
    searchUserWithMobileSA(mobile: $mobile) { 
      ...userFields
    }
  } 
`;
