export const CLUB_FIELDS = `
  fragment clubFields on Club {
    _id
    name
    abbreviation
    logo
    location {
      address
      coordinates
      type
    }
    founded
    country
    sport
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isFC
    isPremium
    timezone
  }
`;

export const CLUB_FIELDS_WITH_THEME = `
fragment clubFieldsWithTheme on ClubWithTheme {
    _id
    name
    abbreviation
    logo
    location {
      address
      coordinates
      type
    }
    founded
    country
    sport
    contactInfo {
      phones
      emails
      websiteUrl
    }
    leagues
    isFC
    isPremium
    timezone
    theme
    totalMembers
  }
`;

export const CREATE_CLUB = `
  mutation createClub($name: String!, $abbreviation: String, $founded: Int, $country: String!, $sport: String!, $contactInfo: ContactInfoInput, $location: LocationInput, isPremium: Boolean, $timezone: String) {
    createClub(name: $name, abbreviation: $abbreviation, founded: $founded, country: $country, sport: $sport, contactInfo: $contactInfo, location: $location, isPremium: $isPremium, timezone: $timezone) {
      _id
    }
  }
`;

export const REGISTER_CLUB_USER = `
 mutation registerClubUser($firstName: String!, $lastName: String, $mobile: String!, $role: String!, $clubId: String, $teamId: String) {
  registerClubUser(firstName: $firstName, lastName: $lastName, mobile: $mobile, role: $role, clubId: $clubId, teamId: $teamId) {
      _id
    }
  }
`;
