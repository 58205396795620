export const PROFILE_FIELDS = `
  fragment profileFields on Profile {
    _id
    firstName
    lastName
    mobile
    club
    teams
    yearOfBirth
    height
    weight
    userId
    photo
    parents
  }
`;

export const PROFILE_FIELDS_WITH_INVITATION_STATUS = `
  fragment profileFieldsWithInvitationStatus on ProfileWithInvitationStatus {
    _id
    firstName
    lastName
    mobile
    club
    teams
    yearOfBirth
    height
    weight
    favourites {
      teams
      players
      brands
    }
    userId
    photo
    parents
    invitationStatus
  }
`;

export const GET_PROFILES = `
  ${PROFILE_FIELDS_WITH_INVITATION_STATUS}
  query GetClubProfiles($term: String, $clubId: String, $teamId: String, $skip: Int, $limit: Int, $sortingDir: String, $sortingField: String) {
    ClubProfiles(term: $term, clubId: $clubId, teamId: $teamId, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
      ...profileFieldsWithInvitationStatus
    }
  }
`;

export const COUNT_PROFILES = `
  query GetClubProfiles($term: String, $clubId: String!, $teamId: String, $skip: Int, $limit: Int) {
    ClubProfiles(term: $term, clubId: $clubId, teamId: $teamId, skip: $skip, limit: $limit) {
      _id
    }
  }
`;

export const GET_TEAMS_PROFILES_FOR_SQUAD_SELECTION = `
  query GetTeamsProfilesForSquadSelection($term: String, $clubId: String!, $teamId: String, $skip: Int, $limit: Int) {
    ClubProfiles(term: $term, clubId: $clubId, teamId: $teamId, skip: $skip, limit: $limit) {
        _id
        firstName
        lastName
        photo
    }
  }
`;

export const GET_TEAMS_PROFILES_IDS = `
   ${PROFILE_FIELDS_WITH_INVITATION_STATUS}
  query GetTeamsProfilesIds($term: String, $clubId: String!, $teamId: String, $skip: Int, $limit: Int) {
    ClubProfiles(term: $term, clubId: $clubId, teamId: $teamId, skip: $skip, limit: $limit) {
      _id
    }
  }
`;


export const CREATE_PROFILE = `
  mutation CreateClubProfile($firstName: String,
    $lastName: String,
    $mobile: String,
    $club: String,
    $teams: [String],
    $yearOfBirth: String,
    $height: String,
    $weight: String,
    $photo: String) {
      createProfile(firstName: $firstName,
        lastName: $lastName,
        mobile: $mobile,
        club: $club,
        teams: $teams,
        yearOfBirth: $yearOfBirth,
        height: $height,
        weight: $weight,
        photo: $photo) {
          _id
        }
  }
`;

export const UPDATE_PROFILE = `
  mutation UpdateProfile($profileId: String!,
    $firstName: String,
    $lastName: String,
    $mobile: String,
    $club: String,
    $teams: [String],
    $yearOfBirth: String,
    $height: String,
    $weight: String) {
      updateProfile(profileId: $profileId,
        firstName: $firstName,
        lastName: $lastName,
        mobile: $mobile,
        club: $club,
        teams: $teams,
        yearOfBirth: $yearOfBirth,
        height: $height,
        weight: $weight) {
          _id
        }
  }
`;

export const GET_PROFILE_BY_ID = `
  query ($ids: [String]!) {
    profilesById(ids: $ids) {
      _id
      firstName
      lastName
      mobile
      club
      teams
      yearOfBirth
      photo
      parents
      userId
    }
  }
`;

export const GET_PROFILE_NOTE = `
query ($profileId: String, $clubId: String) {
  getProfileNote (profileId: $profileId, clubId: $clubId) {
    _id
    profileId
    clubId
    note
  }
}
`;

export const CREATE_PROFILE_NOTE = `
mutation ($profileId: String, $clubId: String, $text: String) {
  createProfileNote (profileId: $profileId, clubId: $clubId, text: $text) {
    _id
    profileId
    clubId
    note
  }
}
`;

export const UPDATE_PROFILE_NOTE = `
mutation ($noteId: String, $clubId: String, $text: String) {
  updateProfileNote (noteId: $noteId, clubId: $clubId, text: $text) {
    _id
    profileId
    clubId
    note
  }
}
`;

export const DELETE_PROFILE_NOTE = `
mutation ($noteId: String, $clubId: String) {
  deleteProfileNote (noteId: $noteId, clubId: $clubId)
}
`;