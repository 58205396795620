import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, MenuItem, Stack, TextField} from "@mui/material";
import useStyles from "./formStyles";

const SelectRenderer = ({options, name, value, onChange, disabled, error ='', label, styles = useStyles(), multiple, colorPicker}) => {

    return (<Stack direction={'column'} spacing={0.5} sx={{width: '100%'}}>
            <span className={styles.label}>{label}</span>
            <FormControl fullWidth>
                <TextField select
                           key={name}
                           id="team-select"
                           value={value}
                           classes={{notchedOutline: styles.input}}
                           style={{height: '40px'}}
                           inputProps={{style: {height: '40px', paddingBottom: '22px', classes: {notchedOutline: styles.input}}}}
                           InputProps={{style: {height: '40px'}, classes: {notchedOutline: styles.input}}}
                           disabled={disabled}
                           name={name}
                           FormHelperTextProps={{
                               classes:{
                                   root: styles.helperText
                               }
                           }}
                           SelectProps={{multiple}}
                           error={!!error}
                           helperText={error}
                           onChange={onChange}>
                    {options.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {colorPicker ? (
                                <>
                                    <div
                                        className={styles.colorDiv}
                                        style={{
                                            backgroundColor: item.value,
                                        }}
                                    />
                                    <span style={{ verticalAlign: "middle" }}>
                                        {item.text}
                                    </span>
                                </>
                                ) : (
                                    item.text
                                )
                            }
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>

        </Stack>
    );
};

SelectRenderer.propTypes = {};

export default SelectRenderer;
