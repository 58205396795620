import { PERSON_RESOLVED_FIELDS } from "../../graphql/userGraphql";
import { EVENT_FIELDS } from "../Activities/EventsService";

export const TEAM_FIELDS = `
  fragment teamFields on Team {
    _id
    name
    photo
    ageGroup
    season
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isFC
    isLocked
  }
`;

export const TEAM_WITH_SPORT_FIELDS = `
  fragment teamWithSportFields on TeamWithSport {
    _id
    name
    photo
    ageGroup
    season
    sport
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isFC
    isLocked
    logo
  }
`;

export const TEAM_FIELDS_WITH_STAFF = `
  ${PERSON_RESOLVED_FIELDS}
  fragment teamFieldsWithStaff on TeamWithStaff {
    _id
    name
	logo
    photo
    ageGroup
    season
    sport
    club
    teamAdminUsers
    coaches
    teamCode
    leagues
    isClaimed
    isFC
    isLocked
    coachesInfo {
      ...personResolvedFields
    },
    teamAdminsInfo {
      ...personResolvedFields
    }
  }
`;

export const GET_TEAM_DETAILS = `
  ${TEAM_FIELDS_WITH_STAFF}
   query ClubTeamsWithStaffAndClub($teamIds: [String]!, $skip: Int, $limit: Int) {
    teamDetails(teamIds: $teamIds, skip: $skip, limit: $limit) {
      ...teamFieldsWithStaff
    }
  }
`;

export const GET_TEAMS = `
  query GetTeamsForPosts ($clubId: String!, $skip: Int, $limit: Int) {
    ClubTeams(clubId: $clubId, skip: $skip, limit: $limit) {
      _id
      name
      ageGroup
      teamCode
      teamAdminUsers
      coaches
    }
  }
`;

export const GET_CLUBS_TEAMS_ALL_FIELDS = `
  ${TEAM_WITH_SPORT_FIELDS}
  query GetClubsTeams($clubId: String!, $skip: Int, $limit: Int, $sortingDir: String, $sortingField: String) {
    ClubTeams(clubId: $clubId, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
      ...teamWithSportFields
    }
  } 
`;

export const CREATE_TEAM = `
  mutation CreateClubTeam(
    $name: String!,
    $clubId: String!,
    $photo: String,
    $ageGroup: String,
    $isFC: String) {
      createTeam(name: $name,
        clubId: $clubId,
        photo: $photo,
        ageGroup: $ageGroup,
        isFC: $isFC
      ) {
        _id
      }
    }
`;

export const UPDATE_TEAM = `
   mutation UpdateTeam(
    $teamId: String!,
    $name: String,
    $ageGroup: String,
    $isFC: String) {
      editTeam(teamId: $teamId,
        name: $name,
        ageGroup: $ageGroup,
        isFC: $isFC
      ) {
        _id
      }
    }
`;

export const UPLOAD_IMAGE = `
  mutation ($uploadType: String!, $itemId: String!, $fileName: String!) {
    uploadImage(uploadType: $uploadType, itemId: $itemId, fileName: $fileName)
  }
`;

export const DELETE_IMAGE = `
  mutation ($deleteType: String!, $itemId: String!, $fileName: String!) {
    deleteImage(deleteType: $deleteType, itemId: $itemId, fileName: $fileName)
  }
`;

export const DELETE_TEAM = `
  mutation ($teamId: String!) {
  deleteTeam(teamId: $teamId)
}
`;

export const DELETE_PROFILE = `
  mutation ($profileId: String!) {
  deleteProfile(profileId: $profileId)
}
`;

export const REMOVE_PLAYER_FROM_PROFILE = `
  mutation ($profileId: String!) {
  removePlayerFromProfile(profileId: $profileId) {
    _id
  }
}
`;

export const GET_PROFILE_ATTENDANCE = ` 
 ${EVENT_FIELDS}
  query ($profileId: String!, $teamId: String!, $fromDate: String!, $fromTime: String!, $toDate: String!, $toTime: String!, $eventType: String) {
  getProfileAttendance(profileId: $profileId, teamId: $teamId, fromDate: $fromDate, fromTime: $fromTime, toDate: $toDate, toTime: $toTime, eventType: $eventType) {
    events {
      ...eventFields
    }
    totalEvents
    attendedEvents
    notAttended
    totalAttendance
    ranking
  }
}
`;

export const GET_TEAM_ATTENDANCE = ` 
 ${EVENT_FIELDS}
  query ($teamId: String!, $fromDate: String!, $fromTime: String!, $toDate: String!, $toTime: String!, $eventType: String, $sort: String) {
  getTeamAttendances(teamId: $teamId, fromDate: $fromDate, fromTime: $fromTime, toDate: $toDate, toTime: $toTime, eventType: $eventType, sort: $sort) {
   profileId
   attendances {
    events {
    ...eventFields
    }
    totalEvents
    attendedEvents
    notAttended
    totalAttendance
	ranking
   }
  }
}
`;

export const TOGGLE_LOCK_TEAM = `
  mutation ($teamId: String!) {
    toggleLockTeam(teamId: $teamId) {
      isLocked
    }
  }
`;

export const GENERATE_TEAM_CODE = `
  mutation ($teamId: String!) {
    editTeamCode(teamId: $teamId)
  }
`;
