import React from "react";
import { useTranslation } from "../../../context/Internationalization";
import Table from "../../../Components/Table/Table";
import ClubsTableRow from "./ClubsTableRow";

const schema = (t) => [
  {
    id: "logo",
    label: t("logo"),
  },
  {
    id: "name",
    label: t("club_name"),
  },
  {
    id: "country",
    label: t("country"),
  },
  {
    id: "founded",
    label: t("founded"),
  },
  {
    id: "sport",
    label: t("sport"),
  },
  {
    id: "websiteUrl",
    label: t("website"),
  },
  {
    id: "isPremium",
    label: t("isPremium"),
  },
  {
    id: "isFC",
    label: t("isFC"),
  },
  {
    id: "actions",
    label: "",
  },
];

const ClubsTable = ({ clubs, menuActions, lastElementRef }) => {
  const { t } = useTranslation();
  const rowElement = (club, index) => (
    <ClubsTableRow
      key={club._id}
      lastElementRef={lastElementRef}
      club={club}
      index={index}
      menuActions={menuActions}
    />
  );

  return <Table rows={clubs} columns={schema(t)} rowElement={rowElement} />;
};

export default ClubsTable;
