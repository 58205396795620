import firebase from "./firebase";
import "firebase/compat/auth";

export const appVersion = process.env.REACT_APP_VERSION || "2.5.0";

export const getStorage = () => {
  return localStorage.getItem("rememberMe") ? localStorage : sessionStorage;
};

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  const user = getStorage().getItem("user");
  const token = getStorage().getItem("token");
  if (user && token) {
    try {
      const currentUser = firebase.auth().currentUser;
      return !!currentUser;
    } catch (error) {
      console.error("Error verifying login status:", error);
      return false;
    }
  }
  return false;
};

export const hasUserVisibility = (routeRoles) => {
  if (!routeRoles) {
    return true;
  }

  if (!isUserLoggedIn()) {
    return false;
  }

  const user = JSON.parse(getStorage().getItem("user"));
  const userRoles = user?.roles;

  if (!userRoles.length) {
    return false;
  }

  return Boolean(
    userRoles.find((userRole) => {
      return Boolean(routeRoles.find((role) => role === userRole));
    })
  );
};
// export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const getUserData = () =>
  getStorage().getItem("user")
    ? JSON.parse(getStorage().getItem("user"))
    : null;

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};

export const signOut = () => {
  return firebase
    .auth()
    .signOut()
    .then((confirmationResult) => {
      console.log("Successfully signed out");
      return confirmationResult;
    })
    .catch((error) => {
      console.error("Error signing out:", error);
      throw new Error("Unable to sign out");
    });
};

function setUpRecaptcha() {
  try {
    return new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: () => {
        console.log("reCAPTCHA resolved");
      },
      "expired-callback": () => {
        console.log("reCAPTCHA expired");
      },
    });
  } catch (error) {
    console.error("Error setting up reCAPTCHA:", error);
    throw error;
  }
}

export const signIn = (mobile) => {
  const appVerifier = setUpRecaptcha();
  return firebase
    .auth()
    .signInWithPhoneNumber(mobile, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      return confirmationResult;
    })
    .catch((error) => {
      console.error("SMS not sent:", error.message);
      throw new Error("Unable to send verification SMS. Please try again.");
    });
};

export const verificationCode = (confirmationResult, code) => {
  return confirmationResult
    .confirm(code)
    .then((result) => {
      // User signed in successfully.
      const user = result.user;
      getStorage().setItem("refreshToken", user?.refreshToken);
      return user?.getIdToken(true);
    })
    .catch((error) => {
      console.error("Invalid verification code:", error.message);
      throw new Error("Invalid code. Please try again.");
    });
};

export const getFirebaseUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user && Object.keys(user).length > 0) {
        process.env.REACT_APP_NODE_ENV === "development" &&
          console.log(
            "[utils.js - getFirebaseUser] user => ",
            JSON.stringify(user)
          );
        resolve(user);
      } else {
        reject();
      }
    });
  });
};

export const clearCookies = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const clearStorages = (appKeys = ["user", "token"]) => {
  // Remove only app-specific keys from localStorage
  appKeys.forEach((key) => localStorage.removeItem(key));
  appKeys.forEach((key) => sessionStorage.removeItem(key));
};

const checkAppVersion = async () => {
  const savedVersion = localStorage.getItem("appVersion");
  if (savedVersion !== appVersion) {
    await signOut();
    const appKeys = ["user", "token", "refreshToken, tokenLastValidity"];
    clearStorages(appKeys);
    clearCookies();
    console.log(`Version mismatch. Cleared storage for version ${appVersion}`);
  }
};

// Call this at app initialization
await checkAppVersion();
