import React, { useContext, useState } from "react";
import { useTranslation } from "../../context/Internationalization";
import { AuthContext } from "../../context/AuthContext";
import {
  GET_USER_PROFILES_SA,
  SEARCH_USER_WITH_MOBILE_SA,
} from "./SuperAdminService";
import { Box, Button, Grid } from "@mui/material";
import boxStyles from "../../styles/boxing";
import Stack from "@mui/material/Stack";
import MainTitle from "../../Components/Common/MainTitle/MainTitle";
import Divider from "@mui/material/Divider";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import { GET_RESOLVE_USERS, GET_USER } from "../../graphql/userGraphql";
import ImpersonateConfirmationModal from "./UsersTable/ImpersonateConfirmationModal";
import { useSuperAdmin } from "../../context/SuperAdminContext";
import { getStorage } from "../../auth/utils";
import { useNavigate } from "react-router-dom";
import Form from "../../Components/Form/Form";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import useStyles from "../Forms/formsStyles";
import ProfilesTable from "./ProfilesTable/ProfilesTable";
import TeamsTable from "./TeamsTable/TeamsTable";
import UserInvitationsTable from "./UserInvitations/UserInvitationsTable";
import UserClubTable from "./UserClubTable/UserClubTable";
import { SnackBarContext } from "../../context/SnackBarContext";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";

const schema = (t) => [
  {
    name: "firstName",
    label: t("firstName"),
    disabled: true,
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    name: "lastName",
    label: t("lastName"),
    disabled: true,
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    name: "mobile",
    label: t("mobile"),
    disabled: true,
    renderer: TextFieldRenderer,
    row: 1,
  },
];

const initialState = {
  user: null,
  loading: false,
};

const SuperAdminImpersonate = (props) => {
  const { t, switchLanguage } = useTranslation();
  const [state, setState] = useState(initialState);
  const [filters, setFilters] = useState({ id: "", mobile: "" });
  const { authState, setImpersonate } = useContext(AuthContext);
  const { setUser } = useSuperAdmin();
  const navigate = useNavigate();
  const styles = useStyles();
  const snackBar = useContext(SnackBarContext);
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  const handleImpersonate = () => {
    setState({ ...state, impersonateModal: true });
  };

  const searchByMobile = (mobile) => {
    axiosGraphQL({
      query: SEARCH_USER_WITH_MOBILE_SA,
      variables: { mobile: mobile.replace("+", "") },
    })
      .then((response) => {
        const user =
          response?.data?.data?.searchUserWithMobileSA &&
          response?.data?.data?.searchUserWithMobileSA[0];
        if (user) {
          axiosGraphQL({
            query: GET_USER_PROFILES_SA,
            variables: { userId: user?._id },
          }).then((response) => {
            setState({
              user: user,
              profiles: response?.data?.data?.getClubProfilesSA,
            });
          });
        }
      })
      .catch((error) => {
        errorLog(
          error,
          "SuperAdminImpersonate",
          "resolveUser",
          "SEARCH_USER_WITH_MOBILE_SA"
        );
        setState((prevState) => ({ ...prevState, loading: false }));
        snackBar.setOptions({
          alertMessage: error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const resolveUser = () => {
    setState((previousState) => ({
      ...previousState,
      loading: true,
      user: null,
    }));
    if (filters.mobile && filters.mobile.length > 0) {
      searchByMobile(filters.mobile);
    } else if (filters.id && filters.id.length > 0) {
      axiosGraphQL({
        query: GET_RESOLVE_USERS,
        variables: {
          ids: [filters.id],
          skip: 0,
          limit: 20,
        },
      })
        .then((response) => {
          const { resolveUserIds } = response.data.data;
          searchByMobile(resolveUserIds[0].mobile);
        })
        .catch((error) => {
          errorLog(
            error,
            "SuperAdminImpersonate",
            "resolveUser",
            "GET_RESOLVE_USERS"
          );
          setState((prevState) => ({ ...prevState, loading: false }));
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }
  };

  const onChangeFilter = ({ target: { name, value } }) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const onImpersonate = () => {
    setUser(state.user);
    setTimeout(() => {
      axiosGraphQL({ query: GET_USER })
        .then(async (response) => {
          if (
            response.data.data.me.club &&
            response.data.data.me.clubs &&
            response.data.data.me.user
          ) {
            const user = response.data.data.me.user;
            const userLangDB = user.language;
            const userLangLS = getStorage().getItem("language");
            if (userLangDB !== userLangLS) {
              switchLanguage(userLangDB);
            }
            user.club = response.data.data.me.club;
            user.clubs = response.data.data.me.clubs;
            getStorage().setItem("impersonate", JSON.stringify(user));
            setImpersonate(user);
            navigate("/");
          }
        })
        .catch((error) => {
          errorLog(error, "SuperAdminImpersonate", "onImpersonate", "GET_USER");
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }, 100);
  };

  const onCloseModal = () => {
    setState({ ...state, impersonateModal: false });
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Box>
        <ImpersonateConfirmationModal
          open={state.impersonateModal}
          onClose={onCloseModal}
          onImpersonate={onImpersonate}
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={boxStyles.headerContainer} component="div">
              <Stack direction={"column"} spacing={1}>
                <MainTitle title={t("search_user")} />
              </Stack>
            </Box>
            <Divider style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} style={boxStyles.filtersContainer}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
            >
              <Stack direction={"row"} spacing={1} alignItems={"end"}>
                <Grid item xs={4}>
                  <TextFieldRenderer
                    label={"Id"}
                    onChange={onChangeFilter}
                    name={"id"}
                    value={filters.id}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldRenderer
                    label={t("mobile")}
                    onChange={onChangeFilter}
                    name={"mobile"}
                    value={filters.mobile}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button type="button" size="small" onClick={resolveUser}>
                    {t("search_user")}
                  </Button>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {state.user && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  onClick={handleImpersonate}
                  disabled={!state.user?.roles?.includes("club-admin")}
                >
                  Impersonate
                </Button>
              </Stack>
              <Form schema={schema(t)} values={state.user || {}} />
            </Grid>
            <Grid item xs={12}>
              <Accordion className={styles.cardContainer}>
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {t("clubs")}
                </AccordionSummary>
                <AccordionDetails>
                  <UserClubTable data={state.user?.clubs || []} />
                </AccordionDetails>
              </Accordion>
              <Accordion className={styles.cardContainer}>
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {t("teams")}
                </AccordionSummary>
                <AccordionDetails>
                  <TeamsTable
                    data={
                      state?.user?.teams?.map((team) => ({
                        ...team,
                        userId: state.user?._id,
                      })) || []
                    }
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion className={styles.cardContainer}>
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {t("user_invitations")}
                </AccordionSummary>
                <AccordionDetails>
                  <UserInvitationsTable data={state?.user?.invitations} />
                </AccordionDetails>
              </Accordion>
              <Accordion className={styles.cardContainer}>
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {t("profiles")}
                </AccordionSummary>
                <AccordionDetails>
                  <ProfilesTable data={state.profiles ? state.profiles : []} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

SuperAdminImpersonate.propTypes = {};

export default SuperAdminImpersonate;
