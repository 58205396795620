import React from "react";
import { getStorage } from "../auth/utils";
import { DateTime } from "luxon";
import {
  ACTIVITY_TYPES,
  EventTimeType,
  PAYMENT_CATEGORIES,
  POST_TYPES,
  PRODUCT_ICONS_BY_CATEGORY,
} from "./Enum";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { GET_TEAM_DETAILS } from "../pages/Teams/TeamsService";
import { EDIT_USER } from "../graphql/userGraphql";
// import word from "../assets/images/icons/file-icons/word.svg";
import pdf from "../assets/images/icons/file-icons/pdf.svg";
// import excel from "../assets/images/icons/file-icons/excel.svg";
// import powerpoint from "../assets/images/icons/file-icons/powerpoint.svg";
import TrainingIcon from "../assets/images/icons/training_schedule.png";
import MatchIcon from "../assets/images/icons/match_schedule.png";
import EventIcon from "../assets/images/icons/event_schedule.png";
import AnnouncementIcon from "../assets/images/icons/announcement.png";
import subscriptionIcon from "../assets/images/icons/payment-icon/subscription-icon.svg";
import teamwareIcon from "../assets/images/icons/payment-icon/teamware-icon.svg";
import tournamentIcon from "../assets/images/icons/payment-icon/tournament-icon.svg";
import toursIcon from "../assets/images/icons/payment-icon/tours-icon.svg";
import eventIcon from "../assets/images/icons/payment-icon/event-icon.svg";
import otherIcon from "../assets/images/icons/payment-icon/other-icon.svg";
import shortTextIcon from "../assets/images/forms-icons/short-text-icon.svg";
import longTextIcon from "../assets/images/forms-icons/long-text-icon.svg";
import checkboxIcon from "../assets/images/forms-icons/checkboxes-icon.svg";
import dateIcon from "../assets/images/forms-icons/date-icon.svg";
import dropdownIcon from "../assets/images/forms-icons/dropdown-icon.svg";
import emailIcon from "../assets/images/forms-icons/email-icon.svg";
import fileUploadIcon from "../assets/images/forms-icons/file-upload-icon.svg";
import legalIcon from "../assets/images/forms-icons/legal-icon.svg";
import phoneIcon from "../assets/images/forms-icons/phone-icon.svg";
import linearScaleIcon from "../assets/images/forms-icons/linear-scale-icon.svg";
import multipleChoicesIcon from "../assets/images/forms-icons/multiple-choices-icon.svg";
import numberIcon from "../assets/images/forms-icons/number-icon.svg";
import websiteIcon from "../assets/images/forms-icons/website-icon.svg";
import yesNoIcon from "../assets/images/forms-icons/yes_no_icon.svg";

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

export const homeLinks = ["/", "/news", "/players", "/payments"];

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
//export const isUserLoggedIn = () => localStorage.getItem("userData");
export const isUserLoggedIn = () =>
  getStorage().getItem("token") && getStorage().getItem("user");
export const getUserData = () => JSON.parse(getStorage().getItem("user"));

export const fileIcons = [
  {
    type: "application/pdf",
    icon: <img src={pdf} alt={"pdf-file-icon"} width={100} height={100} />,
  },
  // {
  //   type: "application/msword",
  //   icon: <img src={word} alt={"doc-file-icon"} width={100} height={100} />,
  // },
  // {
  //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //   icon: <img src={word} alt={"docx-file-icon"} width={100} height={100} />,
  // },
  // {
  //   type: "application/vnd.ms-excel",
  //   icon: <img src={excel} alt={"xls-file-icon"} width={100} height={100} />,
  // },
  // {
  //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   icon: <img src={excel} alt={"xlsx-file-icon"} width={100} height={100} />,
  // },
  // {
  //   type: "application/vnd.ms-powerpoint",
  //   icon: (
  //     <img src={powerpoint} alt={"ppt-file-icon"} width={100} height={100} />
  //   ),
  // },
  // {
  //   type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  //   icon: (
  //     <img src={powerpoint} alt={"pptx-file-icon"} width={100} height={100} />
  //   ),
  // },
];

export const getMimeType = (fileExtension) => {
  const mimeTypes = {
    svg: "image/svg+xml",
    png: "image/png",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    gif: "image/gif",
    webp: "image/webp",
    mp4: "video/mp4",
    mpeg: "video/mpeg",
    avi: "video/x-msvideo",
    pdf: "application/pdf",
    // doc: "application/msword",
    // docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // xls: "application/vnd.ms-excel",
    // xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // ppt: "application/vnd.ms-powerpoint",
    // pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  };

  return mimeTypes[fileExtension.toLowerCase()];
};

export const getUrlParamsPrefix = (url) => {
  let prefix = "?";
  if (url?.indexOf("?") > -1) {
    prefix = "&";
  }
  return prefix;
};

let timerId;

export const throttleFunction = function (func, delay) {
  // If setTimeout is already scheduled, no need to do anything
  if (timerId) {
    return;
  }

  // Schedule a setTimeout after delay seconds
  timerId = setTimeout(function () {
    func();

    // Once setTimeout function execution is finished, timerId = undefined so that in <br>
    // the next scroll event function execution can be scheduled by the setTimeout
    timerId = undefined;
  }, delay);
};

export const DATETIME_FORMAT = "yyyy/MM/dd HH:mm";
export const DATE_FORMAT = "yyyy/MM/dd";
export const TIME_FORMAT = "HH:mm";

export const getSeasonDate = (format = DATETIME_FORMAT) => {
  // start date is 1st of July
  return DateTime.fromObject({
    year: getSeasonYear(),
    month: 7,
    day: 1,
  }).toFormat(format);
};

export const getLastDayOfSeason = (format = DATETIME_FORMAT) => {
  // end date is 30th of June
  return DateTime.fromObject({
    year: getSeasonYear() + 1,
    month: 6,
    day: 30,
  }).toFormat(format);
};

export const getDateStringWithFormat = (date, format = DATETIME_FORMAT) => {
  return DateTime.fromJSDate(date).toFormat(format);
};

export const getAgeGroup = (ageGroup) => {
  if (!isNaN(ageGroup)) {
    return `U${ageGroup}`;
  } else {
    return ageGroup.charAt(0).toUpperCase() + ageGroup.slice(1);
  }
};

export const determineIconFromType = (type) => {
  switch (type) {
    case ACTIVITY_TYPES.TRAINING:
      return TrainingIcon;
    case ACTIVITY_TYPES.MATCH:
      return MatchIcon;
    case ACTIVITY_TYPES.EVENT:
      return EventIcon;
    case POST_TYPES.TEXT:
      return AnnouncementIcon;
  }
};

export const determineProductIconFromType = (type) => {
  switch (type) {
    case PAYMENT_CATEGORIES.SUBSCRIPTION:
      return subscriptionIcon;
    case PAYMENT_CATEGORIES.TEAMWARE:
      return teamwareIcon;
    case PAYMENT_CATEGORIES.TOURNAMENT:
      return tournamentIcon;
    case PAYMENT_CATEGORIES.TOURS:
      return toursIcon;
    case PAYMENT_CATEGORIES.EVENT:
      return eventIcon;
    case PAYMENT_CATEGORIES.OTHER:
      return otherIcon;
  }
};

export const getUpdatedText = (event, updateEvent, t) => {
  const diffs = checkEventDiffs(event, updateEvent);
  const getNameFrom = (diff) => {
    switch (diff) {
      case "time":
        return t("time");
      case "date":
        return t("date");
      case "location":
        return t("location");
      default:
        return "";
    }
  };

  if (diffs.length === 0) {
    return "";
  }
  if (diffs.length === 1) {
    return `${t("newFemale")} ${getNameFrom(diffs[0])}.`.toLocaleLowerCase();
  }
  var string = `${t("newFemale")}`;
  diffs.forEach((diff, index) => {
    string += `${
      index === diffs.length - 1 ? ` ${t("and")}` : ""
    } ${getNameFrom(diff)}${index < diffs.length - 2 ? ", " : ""}`;
  });

  return string.toLocaleLowerCase() + ".";
};

export function checkEventDiffs(event, oldValues) {
  let failedChecks = [];

  oldValues.location.coordinates = [
    oldValues?.location?.lng,
    oldValues?.location?.lat,
  ];

  if (!event || !oldValues) {
    return [];
  }

  if (
    !arraysMatch(event?.location?.coordinates, oldValues?.location?.coordinates)
  ) {
    failedChecks.push("location");
  }

  const diffStart = formattedDate(event.fromTime)
    .diff(oldValues.fromTime, ["hours"])
    ?.toObject();

  const diffEnd = formattedDate(event.toTime)
    .diff(oldValues.toTime, ["hours"])
    ?.toObject();

  const diffDate = formattedDate(event.fromTime)
    .diff(oldValues.date, ["hours"])
    ?.toObject();

  if (Math.abs(diffDate.hours) >= 24 || Math.abs(diffEnd.hours) >= 24) {
    failedChecks.push("date");
  } else if (
    oldValues.fromTime.toMillis() !==
      formattedDate(event.fromTime).toMillis() ||
    oldValues.toTime.toMillis() !== formattedDate(event.toTime).toMillis()
  ) {
    failedChecks.push("time");
  }

  return failedChecks;
}

var arraysMatch = function (arr1, arr2) {
  // Check if the arrays are the same length
  if (arr1?.length !== arr2?.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
};

export function formattedDate(date, format = DATETIME_FORMAT) {
  if (!date) {
    return null;
  }

  return DateTime.fromFormat(date, format);
}

export const getOldDate = (event, withoutTime = false) => {
  return formattedDate(event?.fromDate)?.toFormat(
    `EEEE d MMMM${withoutTime ? "" : ", HH:mm"}`,
    { locale: "el" }
  );
};

export function checkEventTimeType(event) {
  if (!event?.fromTime || !event?.toTime) {
    return EventTimeType.unknown;
  }
  const eventFromDateTime = DateTime.fromFormat(
    event?.fromDate + " " + event?.fromTime,
    DATETIME_FORMAT
  );
  const eventToDateTime = DateTime.fromFormat(
    event?.toDate + " " + event?.toTime,
    DATETIME_FORMAT
  );
  if (
    eventFromDateTime < DateTime.local() &&
    eventToDateTime > DateTime.local()
  ) {
    return EventTimeType.live;
  }

  if (eventToDateTime < DateTime.local()) {
    return EventTimeType.past;
  }

  return EventTimeType.upcoming;
}

export const capitalizeFirstLetter = ([first, ...rest], locale = "en") =>
  first.toLocaleUpperCase(locale) + rest.join("");

export const isSuperAdmin = (user) => {
  if (!user) {
    return false;
  }

  return user?.roles?.includes("super-admin");
};

export const isClubAdmin = (user) => {
  if (!user) {
    return false;
  }

  return user?.roles?.includes("club-admin");
};

export const isSubscribed = (user) => {
  if (!user) {
    return false;
  }

  return user?.isSubscribed;
};

export const createEmptyData = () => {
  const array = [];
  for (let i = 0; i <= 3; i++) {
    array.push({ skeleton: true });
  }
  return array;
};

export const getProductIconByCategory = (category) => {
  return PRODUCT_ICONS_BY_CATEGORY[category];
};

export const formatAmount = (amount = 0, currency = "EUR") => {
  return new Intl.NumberFormat("gr-el", {
    style: "currency",
    currency,
  }).format(amount);
};

export const getSeasonYear = () => {
  const currentDate = DateTime.now();

  if (currentDate.month + 1 <= 7) {
    return currentDate.year - 1;
  }
  return currentDate.year;
};

export const determineIconFromFieldType = (type) => {
  switch (type) {
    case "text":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={longTextIcon}
        />
      );
    case "shortText":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={shortTextIcon}
        />
      );
    case "number":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={numberIcon}
        />
      );
    case "multipleChoice":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={multipleChoicesIcon}
        />
      );
    case "linearScale":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={linearScaleIcon}
        />
      );
    case "checkboxes":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={checkboxIcon}
        />
      );
    case "dropdown":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={dropdownIcon}
        />
      );
    case "email":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={emailIcon}
        />
      );
    case "date":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={dateIcon}
        />
      );
    case "website":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={websiteIcon}
        />
      );
    case "phone":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={phoneIcon}
        />
      );
    case "yes_no":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={yesNoIcon}
        />
      );
    case "legal":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={legalIcon}
        />
      );
    case "file_upload":
      return (
        <Avatar
          variant={"square"}
          style={{ width: 16, height: 16 }}
          src={fileUploadIcon}
        />
      );
  }
};

export const FieldOptions = (t) => [
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("shortText")} <span>{t("short_text")}</span>
      </Stack>
    ),
    value: "shortText",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("text")} <span>{t("long_text")}</span>
      </Stack>
    ),
    value: "text",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("number")} <span>{t("number")}</span>
      </Stack>
    ),
    value: "number",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("multipleChoice")}{" "}
        <span>{t("multiple_choice")}</span>
      </Stack>
    ),
    value: "multipleChoice",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("linearScale")}{" "}
        <span>{t("linear_scale")}</span>
      </Stack>
    ),
    value: "linearScale",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("checkboxes")}{" "}
        <span>{t("checkboxes")}</span>
      </Stack>
    ),
    value: "checkboxes",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("dropdown")} <span>{t("dropdown")}</span>
      </Stack>
    ),
    value: "dropdown",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("date")} <span>{t("date")}</span>
      </Stack>
    ),
    value: "date",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("email")} <span>{t("email")}</span>
      </Stack>
    ),
    value: "email",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("website")} <span>{t("website")}</span>
      </Stack>
    ),
    value: "website",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("phone")} <span>{t("phone")}</span>
      </Stack>
    ),
    value: "phone",
  },
  {
    text: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {determineIconFromFieldType("yes_no")} <span>{t("yes_no")}</span>
      </Stack>
    ),
    value: "yes_no",
  },
  // {
  //   text: <Stack direction={'row'} spacing={1} alignItems={'center'}>{determineIconFromFieldType('legal')} <span>{t('legal')}</span></Stack>,
  //   value: 'legal',
  // },
  // {
  //   text: <Stack direction={'row'} spacing={1} alignItems={'center'}>{determineIconFromFieldType('file_upload')} <span>{t('file_upload')}</span></Stack>,
  //   value: 'file_upload',
  // },
];

export const prepareGroupFieldsForRequest = (groupFields) => {
  return groupFields.map((field) => {
    const _field = {
      ...field,
      options: [...field.options?.map((option) => ({ ...option }))],
    };
    switch (field.type) {
      case "text":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "text";
        _field.validations = [
          {
            type: "maxLength",
            errorLabel: "",
            value: "999",
          },
        ];
        return _field;
      case "shortText":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "shortText";
        _field.validations = [];
        return _field;
      case "number":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "number";

        _field.validations = [
          {
            type: "minValue",
            errorLabel: "",
            value: _field.from,
          },
          {
            type: "maxValue",
            errorLabel: "",
            value: _field.to,
          },
        ];
        _field.from = undefined;
        _field.to = undefined;
        return _field;
      case "multipleChoice":
        _field.id = undefined;
        _field.type = "radio";
        _field.options = _field.options?.map((option) => {
          option.id = undefined;
          return option;
        });
        _field.validations = [];
        return _field;
      case "linearScale":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "linearScale";
        _field.validations = [];

        if (_field.from) {
          _field.validations = [
            {
              type: "minValue",
              errorLabel: "",
              value: _field.from + "",
            },
          ];
        }
        if (_field.to) {
          _field.validations.push({
            type: "maxValue",
            errorLabel: "",
            value: _field.to + "",
          });
        }

        _field.from = undefined;
        _field.to = undefined;
        return _field;
      case "checkboxes":
        _field.id = undefined;

        _field.type = "multipleChoice";
        _field.options = _field.options.map((option) => {
          option.id = undefined;
          return option;
        });

        switch (_field.checkboxCondition) {
          case "selectAtLeast":
            _field.validations = [
              {
                type: "min",
                errorLabel: "",
                value: _field.checkboxConditionNumber,
              },
            ];
            break;
          case "selectEqualsTo":
            _field.validations = [
              {
                type: "min",
                errorLabel: "",
                value: _field.checkboxConditionNumber,
              },
              {
                type: "max",
                errorLabel: "",
                value: _field.checkboxConditionNumber,
              },
            ];
            break;
          case "selectLessThan":
            _field.validations = [
              {
                type: "max",
                errorLabel: "",
                value: _field.checkboxConditionNumber,
              },
            ];
            break;
          default:
            _field.validations = [];
        }

        _field.checkboxCondition = undefined;
        _field.checkboxConditionNumber = undefined;
        return _field;
      case "dropdown":
        _field.id = undefined;
        _field.type = "select";
        const options = _field.dropdownOptions
          ?.split("\n")
          .map((option) => ({ label: option, value: option }));
        _field.validations = [];
        _field.options = options;
        _field.dropdownOptions = undefined;
        return _field;
      case "date":
        _field.id = undefined;
        _field.type = "datetime";
        _field.options = undefined;

        _field.validations = [];
        if (_field.from) {
          _field.validations = [
            {
              type: "minDate",
              errorLabel: "",
              value: _field.from,
            },
          ];
        }
        if (_field.to) {
          _field.validations.push({
            type: "maxDate",
            errorLabel: "",
            value: _field.to,
          });
        }
        _field.from = undefined;
        _field.to = undefined;
        return _field;
      case "email":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "email";

        _field.validations = [];
        return _field;
      case "website":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "website";
        _field.validations = [];
        return _field;
      case "phone":
        _field.id = undefined;

        _field.options = undefined;
        _field.type = "phone";

        _field.validations = [];
        return _field;
      case "yes_no":
        _field.id = undefined;
        _field.options = undefined;
        _field.type = "radio";

        _field.options = [
          {
            label: "no",
            value: "no",
          },
          {
            label: "yes",
            value: "yes",
          },
        ];

        _field.validations = [];
        return _field;
      // case 'legal':
      //   _field.id = undefined
      //   _field.options = undefined
      //   _field.type = 'text'
      //
      //   _field.validations = []
      //   return _field
      // case 'file_upload':
      //   _field.id = undefined
      //   _field.options = undefined
      //   _field.type = 'text'
      //   _field.validations = []
      //   return _field
    }
  });
};

export const generateLeagueCompetitions = (competitions) => {
  const fixtures = flattenFixtures(competitions);

  return mapCompetitions(competitions, fixtures);
};

function flattenFixtures(competitions) {
  const fixtures = [];
  competitions.forEach((competition) => {
    const competitionFixtures = competition.fixtures.map((fixture) => {
      const fixtureMatches = fixture.matches.map((match) => {
        return {
          homeTeam: match.homeTeam,
          awayTeam: match.awayTeam,
          date: match.date ? match.date.toFormat("y/LL/dd") : "TBD",
          time: match.time ? match.time.toFormat("HH:mm") : "TBD",
          timezone: match.timezone,
          status: match.status,
          score: match.score,
          venue: match.venue,
          location: flattenLocation(match.location),
          competitionId: match.competitionId,
          fixtureId: match.fixtureId,
          leagueId: match.leagueId,
        };
      });

      return {
        fixtureName: fixture.fixtureName,
        leagueId: fixture.leagueId,
        competitionId: fixture.competitionId,
        matches: fixtureMatches,
      };
    });

    fixtures.push(...competitionFixtures);
  });

  return fixtures;
}

function flattenLocation(location) {
  return {
    type: "Point",
    address: location?.address,
    coordinates: !(location?.lng && location?.lat)
      ? []
      : [location?.lng, location?.lat],
  };
}

function mapCompetitions(competitions, fixtures) {
  return competitions.map((competition, index) => {
    const competitionFixtures = fixtures.filter(
      (fixture) => fixture.competitionId === competition._id
    );

    return {
      type: competition.type,
      name: competition.name,
      ageGroup: competition.ageGroup,
      format: competition.format,
      startYear: competition.startYear,
      clubsInCompetition: competition.clubsInCompetition,
      teamsInCompetition: competition.teamsInCompetition,
      location: flattenLocation(competition.location),
      fixtures: competitionFixtures,
    };
  });
}

export const getProfilesTeamDetails = async (
  players,
  axiosGraphQL,
  className,
  snackBar,
  errorLog
) => {
  try {
    const playersTeams = players
      .filter((player) => player.teams)
      .map((player) => player.teams)
      .flat();

    let teamDetailsResponse = await axiosGraphQL({
      query: GET_TEAM_DETAILS,
      variables: { teamIds: playersTeams, skip: 0, limit: 10 },
    });

    return teamDetailsResponse.data.data.teamDetails.reduce((total, team) => {
      total[team?._id] = team;
      return total;
    }, {});
  } catch (error) {
    errorLog(error, className, "getProfilesTeamDetails", "GET_TEAM_DETAILS");
    snackBar.setOptions({
      alertMessage: error.message,
      alertSeverity: "error",
    });
    snackBar.setSnackbarOpen(true);
  }

  return null;
};

export const amIHomeTeam = (event, club) =>
  event.homeMatch ? event.club === club?._id : event.opponentClub === club?._id;
