export const POST_FIELDS = `
  fragment postFields on Post {
    _id
    title
    text
    notes
    photos
    files {
      _id
      name
      url
    }
    club
    teams
    notes
    reactions {
      postId
      userId
      reactionType
    }
    createdBy
    createdAt
    updatedAt
  }
`;

export const GET_POSTS = `
  ${POST_FIELDS}
  query GetPosts($skip: Int = 0, $limit: Int = 20) {
    posts(skip: $skip, limit: $limit) {
      ...postFields
    }
  }
`;

export const CREATE_POST = `
  mutation CreatePost($post: PostInput) {
    createPost(post: $post) {
      _id
    }
  }
`;

export const UPDATE_POST = `
  mutation ($id: String, $title: String, $text: String, $files: [String]) {
    editPost(id: $id, title: $title, text: $text, files: $files) {
      _id
    }
  }
`;

export const DELETE_POST = `
  mutation ($id: String!) {
    deletePost(id: $id)
  }
`;

export const GET_CLUB_POSTS = `
${POST_FIELDS}
  query ($clubId: String, $teamId: String, $skip: Int, $limit: Int, $sortingDir: String, $sortingField: String, $fromDate: String, $toDate: String) {
    posts(clubId: $clubId, teamId: $teamId, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField, fromDate: $fromDate, toDate: $toDate) {
      ...postFields
    }
  
  }
 `;

export const GET_CLUB_TEAMS = `
  query ($clubId: String!, $teamsSkip: Int, $teamsLimit: Int) {
   ClubTeams(clubId: $clubId, skip: $teamsSkip, limit: $teamsLimit) {
      _id
      name
      ageGroup
      teamCode
      sport
      club
    }
}
`;
