import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalActions } from "../../../Components/Modal";
import { Button } from "@mui/material";
import ModalTitle from "../../../Components/Modal/ModalTitle";
import ModalBody from "../../../Components/Modal/ModalBody";
import { useTranslation } from "../../../context/Internationalization";

const RemoveClubAdminConfirmationModal = ({
  open: user,
  onClose,
  onRemove,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={user} onClose={onClose}>
      <ModalTitle title={`${t("remove_club_admin")}`} />
      <ModalBody>
        <span>
          {`${t("club_admin_remove_message1")} ${user?.firstName} ${
            user?.lastName
          } ${t("club_admin_remove_message2")} ${user?.mobile} ${t(
            "club_admin_remove_message3"
          )}`}
          <br />{" "}
        </span>
      </ModalBody>
      <ModalActions>
        <Button
          variant={"outlined"}
          color={"primary"}
          fullWidth
          onClick={onClose}
        >
          {t("no")}
        </Button>
        <Button
          variant={"outlined"}
          color={"error"}
          fullWidth
          onClick={onRemove}
        >
          {t("yes")}
        </Button>
      </ModalActions>
    </Modal>
  );
};

RemoveClubAdminConfirmationModal.propTypes = {};

export default RemoveClubAdminConfirmationModal;
