import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';

function EnhancedTableHead(props) {
    const { columns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isSelectable } = props;
    const createSortHandler = (property, isSortable = true) => (event) => {
        if(isSortable) {
            onRequestSort(event, property);
        }
    };

    return (
        <TableHead>
            <TableRow key={1}>
                { isSelectable ? <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> : null}
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.sortable)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function Table({rows, columns, rowElement, onSort, order, orderBy, isSelectable, onAllSelected = () => {}, resetSelections = false}) {

    const [selected, setSelected] = React.useState([]);
    const [allSelected, setAllSelected] = React.useState(false);

    const handleRequestSort = (event, property) => {
        if(onSort) {
            const isAsc = orderBy === property && order === 'asc';
            onSort({direction: isAsc ? 'desc' : 'asc', key: property})
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n._id);
            setSelected(newSelecteds);
            setAllSelected(true);
            return;
        }
        setSelected([]);
        setAllSelected(false);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    useEffect(() => {
        onAllSelected(allSelected);
    }, [allSelected]);

    useEffect(() => {
        if(resetSelections) {
            setSelected([]);
            setAllSelected(false);
        }
    }, [resetSelections]);
        

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/*<EnhancedTableToolbar numSelected={selected.length} />*/}                 
                <TableContainer sx={{ overflowX: 'auto' }}>
                    <MUITable
                        sx={{ width: '100%' }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            columns={columns}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            isSelectable={isSelectable}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);

                                    return (rowElement(row, index, isItemSelected, handleClick))
                                })
                            }

                        </TableBody>
                    </MUITable>
                </TableContainer>
            </Paper>
        </Box>
    );
}

Table.propTypes = {

};

export default Table;
