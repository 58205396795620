import {fonts} from '../theme/fonts'
import {colors} from '../theme/colors'

const {text} = fonts
const {Grey, Success} = colors
const boxStyles = {
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '20px 0'
    },
    title: {
        color: Grey[900],
        marginTop: 0,
        ...text.lg.medium,
    },
    subtitle: {
        color: Grey[500],
        marginTop: 0,
        ...text.md.normal
    },
    filtersContainer: {
        marginBottom: '32px'
    },
    loaderContainer: {
        position: 'absolute',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    itemContainer: {
        margin: '8px 0',
        border: `1px solid ${Grey[200]}`,
        padding: '12px',
        borderRadius: '6px',
        boxShadow: '0px 1px 3px rgba(22, 31, 37, 0.1), 0px 1px 2px rgba(22, 31, 37, 0.06)'
    },
    avatarWithConnectionIndicatorContainer: {
        position: 'relative'
    },
    connectionIndicator: {
        position: 'absolute',
        bottom: 4,
        right: 0,
        width: '36px',
        border: '4px solid white',
        height: '36px',
        borderRadius: '50%',
        backgroundColor: Grey[300]
    },
    connectionIndicatorActive: {
        backgroundColor: Success[500]
    },

}

export default boxStyles
